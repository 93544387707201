<template>
  <h3 class="offering-title text-left">Landscape Maintenance</h3>
  <h5 class="offering-sub text-left">
    Monthly, bi-weekly or weekly landscape maintenance services to keep a
    property looking good all year long.
  </h5>
  <p class="offering-text text-left">Summary</p>
  <a href="#"><button class="offering-button">Schedule Maintenance</button></a>
  <div id="offering-images">
    <img class="offering-image" src="@/assets/img/properties/img6.jpg" />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" />
    <!-- -->
    <img class="offering-image" src="@/assets/img/properties/img6.jpg" />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" /><img
      class="offering-image"
      src="@/assets/img/properties/img6.jpg"
    />
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" />
  </div>
  <a href="#"><button class="offering-button">Schedule Maintenance</button></a>
</template>
