<template>
  <h3 class="offering-title text-left">Landscape Design Services</h3>
  <h5 class="offering-sub text-left">
    Landscape designs for anyone wishing to install a landscape themselves with
    the help of a professional designer.
  </h5>
  <p class="offering-text text-left">Summary</p>
  <a href="#"><button class="offering-button">Request A Design</button></a>
  <div id="offering-images">
    <img class="offering-image" src="@/assets/img/properties/img10.jpg" />
    <img class="offering-image" src="@/assets/img/properties/img6.jpg" />
  </div>
  <a href="#"><button class="offering-button">Request A Design</button></a>
</template>
