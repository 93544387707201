<template>
  <h3 class="offering-title text-left">Landscape Install</h3>
  <h5 class="offering-sub text-left">
    Installation of patios, walls, landscape plants, water features, and other
    outdoor landscaping needs.
  </h5>
  <p class="offering-text text-left">Summary</p>
  <a href="#"><button class="offering-button">Schedule Install</button></a>
  <div id="offering-images">
    <img class="offering-image" src="@/assets/img/services/Hardscape.jpg" />
    <img class="offering-image" src="@/assets/img/properties/img6.jpg" />
  </div>
  <a href="#"><button class="offering-button">Schedule Install</button></a>
</template>
