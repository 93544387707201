<template>
  <PageHeader>
    <h1>Services We Provide</h1>
    <img src="@/assets/img/hero.jpg" />
  </PageHeader>
  <div id="services">
    <div id="service-list">
      <a
        v-for="tab in tabs"
        :key="tab"
        :class="['service-list', { active: currentOffering === tab }]"
        @click="currentOffering = tab"
      >
        Landscape {{ tab }}
      </a>
    </div>

    <div class="offering">
      <component :is="currentOffering" class="tab"></component>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/global/PageHeader.vue";
import Maintenance from "@/components/Services/Maintenance.vue";
import Design from "@/components/Services/Design.vue";
import Install from "@/components/Services/Install.vue";

export default defineComponent({
  components: {
    PageHeader,
    Maintenance,
    Design,
    Install,
  },
  data() {
    return {
      currentOffering: "Maintenance",
      tabs: ["Maintenance", "Design", "Install"],
    };
  },
});
</script>

<style lang="scss">
#services {
  #service-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--tan);
    padding: 30px 0;
    z-index: 100;
    width: 100%;
    margin: auto;

    a {
      width: 300px;
      background: var(--black);
      color: #efefef;
      margin: 10px auto;
      padding: 10px 20px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .offering {
    width: 80%;
    margin: auto;

    .offering-button {
      width: fit-content;
      max-width: 80%;
      padding: 5px 10px;
      height: auto;
      font-size: 30px;
      margin: 20px 0 40px 0;
    }

    #offering-images {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 1px solid var(--dark-green);
      padding: 30px 0;
      img {
        width: 80%;
        max-width: 600px;
        margin: 20px auto;
      }
    }
  }

  @media (min-width: 900px) {
    #service-list {
      display: block;

      a {
        margin: 20px;
      }
    }

    .offering {
      .offering-button {
        margin: 5px 0 20px 0;
      }

      #offering-images {
        flex-direction: row;
        flex-wrap: wrap;

        img {
          max-width: 400px;
          margin: 10px;
        }
      }
    }
  }
}
</style>
